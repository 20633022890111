<template>
  <v-container id="dashboard" fluid tag="section">

    <v-form ref="event">
      <base-material-card icon="mdi-calendar-month">
        <slot slot="title">Events</slot>
        <slot slot="rightButtons">
          <toggle-button text="Add a new Event" openIcon="mdi-plus" @toggle="showEditEvent" :showing="showCreateEventToggle"/>
        </slot>
            <v-row class="ml-2 mr-0" v-if="showCreateEventToggle">
              <v-text-field label="New Event Name" v-model="newEventName" :rules="[utils.requiredRule]"/>
              <v-text-field class="ml-2" label="Email of organiser" v-model="newEventEmail" :rules="[utils.requiredRule, utils.emailRule]"/>
              <v-icon large @click="createEvent">mdi-checkbox-marked-circle</v-icon>
              <v-icon large @click="showCreateEventToggle = false">mdi-close-circle</v-icon>
            </v-row>
            <v-row class="mx-n1 underlinedRow">
              <v-col>Name</v-col>
              <v-col>Status</v-col>
              <v-col>
                <div class="d-flex">
                  <div>Sold</div>
                  <v-spacer/>
                  <div style="text-align:right">Remaining</div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="clickableRow">
                <v-row class="mx-n1 underlinedRow clickableRow" v-for="(event, i) in events" :key="i" @click="selectItem(event)">
                  <v-col>{{event.name}}</v-col>
                  <v-col>
                    <v-chip small v-if="event.published" color="success">Published</v-chip>
                    <v-chip small v-else color="error">Un-Published</v-chip>
                  </v-col>
                  <v-col>
                    <div class="d-flex">
                      <div>{{event.soldTickets}}</div>
                      <v-spacer/>
                      <div style="text-align:right">{{event.remainingTickets}}</div>
                    </div>
                    <v-progress-linear height="10" rounded color="success" :value="percent(event)"/>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
      </base-material-card>
    </v-form>

  </v-container>
</template>
  
<script>
  import Api from '@/services/Api.js'; 
  import Utils from '@/services/Utils.js';
  import Store from '@/services/Store.js';
  
  export default {
    name: 'Events',

    components: {
      BaseMaterialCard: () => import('@/components/base/MaterialCard'),
      Tooltip: () => import('./components/Tooltip'),
      TextField: () => import('./components/TextField'),
      ToggleButton: () => import('./components/ToggleButton'),
    },  
    
    computed: {
      utils: function () {
        return Utils;
      }
    },

    data: () => ({
      newEventName: "",
      newEventEmail: "",
      events: [],
      user: {},
      showCreateEventToggle: false,
      boxOffice: {},
    }),

    created() {
      this.boxOffice.id = this.$route.params.id;
      this.load();
    },
    
    methods: {

      percent(event) {
        if (!event.soldTickets) {
          return 0;
        }
        return  (100 * event.soldTickets / (event.soldTickets + event.remainingTickets)).toFixed(0);
      }, 

      showEditEvent() {
        this.showCreateEventToggle = !this.showCreateEventToggle;
        if (this.showCreateEventToggle) {
          this.newEventEmail = this.boxOffice.email
        }
      },
      
      async load() {
        this.boxOffice = await Api.post(this, "BoxOffice", "read", this.boxOffice);
        Store.store(this.boxOffice);
        this.readEvents();
      },
      
      async readEvents() {
        this.events = await Api.post(this, "Event", "listWithSales", this.boxOffice);
      },

      async createEvent() {
        if (! await Utils.validate(this.$refs.event) ) {
          return;
        }
        var event = {}
        event.name = this.newEventName;
        event.email = this.newEventEmail;
        event.boxOfficeId = this.boxOffice.id;
        await Api.post(this, "Event", "create", event);
        this.newEventName = "";
        this.showCreateEventToggle = false;
        this.readEvents();
      },

      selectItem(event) {
        this.$router.push("/Dashboard/EventDetails/" + event.id)
      },
    }
  }

</script>
